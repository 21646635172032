import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../icon';
import { Separator } from '../styles/filters';
import {
  Button,
  StyledSelectAllButton,
  StyledSelectAllButtonWrapper,
} from '../styles/dropdown';
import { Flex } from '../styles/flex';

/**
 * A button component used as a trigger for the MultiChoiceDropdown component.
 *
 * Props:
 *  className,
 *  id: http id attribute assign to the button element.
 *  label: text to be displayed inside the button elemet;
 *  selectedCount: number indicating the ammount of selected options.
 *  onClick,
 */
export const DropdownButton = ({
  className,
  children,
  id,
  label,
  icon,
  selectedCount,
  onClick,
}) => {
  return (
    <Button
      role="button"
      className={`btn btn-default ${className}`}
      onClick={onClick}
      id={id}
    >
      {children || (
        <>
          {icon && <Icon name={icon} />}
          <span>{label}</span>
          {selectedCount ? ` · ${selectedCount}` : null}
        </>
      )}
    </Button>
  );
};

DropdownButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string,
  selectedCount: PropTypes.number,
  onClick: PropTypes.func,
};

/**
 * A button component used as a trigger for the MultiChoiceDropdown component.
 * It consists of two segments: On the left side there is a text part,
 * separated by the vertical line we render an arrow icon on the right side.
 * These two segments have their own, separate, onClick actions.
 *
 * Props:
 *  className,
 *  disabled,
 *  id: http id attribute assign to the button element.
 *  label: text to be displayed inside the button elemet;
 *  openDropdown: a callback function to be called on the arrow icon click
 *  onClick: a callback function executed when button text is clicked
 *  selectedCount: number indicating the ammount of selected options.
 *  tooltip,
 */
export const SelectAllDropdownButton = ({
  className,
  disabled,
  icon,
  id,
  label,
  onClick,
  openDropdown,
  selectedCount,
  tooltip,
}) => {
  return (
    <StyledSelectAllButtonWrapper
      id={id}
      className={`btn btn-default ${className}`}
      disabled={disabled}
    >
      <div aria-label={tooltip} data-balloon-pos="up">
        <StyledSelectAllButton
          className="select-all"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }}
        >
          <Flex alignItems="center" gap="8px">
            {icon && <StyledIcon name={icon} />}
            <span>
              {label}
              {selectedCount ? ` · ${selectedCount}` : null}
            </span>
          </Flex>
        </StyledSelectAllButton>
        <StyledSeparator />
        <button className="drop-down" onClick={openDropdown}>
          <Icon size={14} name="arrow_drop_down" />{' '}
        </button>
      </div>
    </StyledSelectAllButtonWrapper>
  );
};

SelectAllDropdownButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  selectedCount: PropTypes.number,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  openDropdown: PropTypes.func.isRequired,
};

SelectAllDropdownButton.defaultProps = {
  className: '',
};

const StyledSeparator = styled(Separator)`
  max-height: 24px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 16px;
`;

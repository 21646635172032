import { replaceWhitespace } from '../../../util';

export const buildErrorId = ({ errorText, name, namespace }) => {
  if (!errorText) return '';
  const msg = Array.isArray(errorText) ? errorText.join(' ') : errorText;
  return [namespace, name, replaceWhitespace(msg)].filter(Boolean).join('_');
};

export const generateKeyDownHandler = ({ type, max, tags, setTags }) => {
  // The maxlength attribute is ignored for numeric inputs.
  // We implement it ourselves based on the maximum allowed value.
  const maxLength = max && Math.ceil(Math.log10(max));

  const keyHandlers = {
    integer: intKeyDownHandlerWrapper(maxLength),
    tags: tagsKeyDownHandlerWrapper({ tags, setTags }),
  };

  return keyHandlers[type];
};

const tagsKeyDownHandlerWrapper =
  ({ tags, setTags }) =>
  (event) => {
    const CONTROL_KEYS = new Set(['Enter', ',', 'Tab']);
    const { key, target } = event;

    if (key === 'Backspace' && target.value === '') {
      setTags(tags.slice(0, -1));
    }
    if (
      CONTROL_KEYS.has(key) &&
      target.value !== '' &&
      tags.every((tag) => tag !== target.value)
    ) {
      event.preventDefault();
      setTags([...tags, target.value]);
      target.value = '';
    }
  };

const intKeyDownHandlerWrapper = (maxLength) => (e) => {
  // Codes for control keys that should always be allowed,
  // even when we are programatically restricting input.
  const CONTROL_KEYS = new Set([
    8, // backspace
    9, // tab
    37, // left
    38, // up
    39, // right
    40, // down
    46, // delete
  ]);

  if (!isNaN(Number(e.key))) {
    if (maxLength && e.target.value.length >= maxLength) e?.preventDefault();
  } else if (!CONTROL_KEYS.has(e.keyCode)) {
    e?.preventDefault();
  }
};

// extract the error message based on the meta object, passed down by the Field component.
export const getError = ({
  error,
  modifiedSinceLastSubmit,
  submitError,
  touched,
}) => {
  if (!modifiedSinceLastSubmit && submitError) return submitError;
  return !touched ? null : error || submitError;
};

export const INPUT_TYPE = {
  INTEGER: 'integer',
  LINK: 'link',
  TEXTAREA: 'textarea',
  TEXT: 'text',
  NUMBER: 'number',
};

export const flattenOptions = (options) =>
  options.length && 'options' in options[0]
    ? options.flatMap((group) => group.options)
    : options;

// A function that help us keep the form state consistent after
// programatic value changes.
// In some cases we have to normalize the provided value (editor component),
// or set the default value ourselves (location select in publication form),
// but we don't want any side-effects that go with making a change
// to a form field (making form touched, dirty...).
export const normalizeInitialValue = ({
  form,
  value,
  name,
  isEqual = (a, b) => a === b,
}) => {
  const { touched, initialValues } = form.getState();
  // we have to calculate our own pristine since some fields are
  // formated automatically making fields `ditrty` in form context
  const pristine = Object.values(touched).every((isToched) => !isToched);

  // if the form is not touched by the user and the initial value
  // of the given field is different from the normalized value we
  // reinitialize the form with the normalized value, making it
  // not dirty, aka pristine again in the Form context
  if (pristine && !isEqual(initialValues[name], value)) {
    form.reset({ ...initialValues, [name]: value });
  }
};

// link fields by defaut have value "http://". we want to make sure that
// if the value is indeed "http://" we treat it as an empty field
const normalizeLinkInputValue = (inputString) => {
  const trimmedLink = inputString.trim();
  return trimmedLink === 'https://' ? '' : trimmedLink;
};

// a helper function that takes for values, and a list of link field names,
// ad return normalized values. In this case, we remove link strings that
// consist only from "https://"
export const normalizeLinkValues = (values, linkFields) => {
  const normalizedValues = { ...values };
  linkFields.forEach((field) => {
    const urlString = normalizedValues[field];
    if (urlString) {
      normalizedValues[field] = normalizeLinkInputValue(urlString);
    }
  });
  return normalizedValues;
};

import de from 'date-fns/locale/de';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import useI18n from '../../../../lib/use_i18n';
import BalloonIcon from '../../balloon_icon';
import { Field } from '../field';
import { InputWrapper } from '../input_wrapper';
import { getError } from '../utils';
import { CalendarHeader } from './calendar_header';
import {
  DateInputIconWrapper,
  DateInputWrapper,
  DATEPICKER_WRAPPER_ID,
} from './styles';
import { objToISODate, stringToDate } from './utils';

registerLocale('de', de);

const DEFAULT_FORMAT = 'P'; // takes default format from locale

const FORMAT = {
  de: 'd.M.yyyy', // the default one would be "dd.MM.yyyy"
};

/**
 * Provides a standalone date input component with calendar picker functionality.
 * Handles date formatting, validation, and locale-specific display.
 *
 * Props:
 *   errorText: error message to display below the input
 *   hint: helper text to display below the input
 *   hintId: id for the hint element for accessibility
 *   inline: if true, text input field will not be rendered, but we will immedately
 *           see the calendar components.
 *   format: custom date format string
 *   label: label text for the input
 *   labelProps: additional props for the label component
 *   name: unique identifier for the input
 *   required: whether the field is required
 *   value: current date value
 *   minDate: earliest selectable date
 *   maxDate: latest selectable date
 *   monthsShown: the number of months visible when calendar component is opened.
 *                it defaults to 1 for date picker, but 2 for range picker.
 *   onChange: callback function when date changes
 *
 *
 * Beside above mentioned props, we can provde the DateInput component
 * with any valid DatePicker props, and it will passed down to the DatePicker
 * component.
 * To see all available options visit https://reactdatepicker.com/
 */
export const DateInput = ({
  errorText,
  format,
  hint,
  hintId,
  inline,
  label,
  labelProps,
  maxDate,
  minDate,
  monthsShown,
  name,
  onChange,
  required,
  value,
  ...datePickerProps
}) => {
  const { locale } = useI18n('date_input');
  const dateFormat = format || FORMAT[locale] || DEFAULT_FORMAT;
  return (
    <InputWrapper
      name={name}
      errorText={errorText}
      labelProps={labelProps || { label, required }}
      hint={hint}
      hintId={hintId}
    >
      <DateInputWrapper id={inline ? DATEPICKER_WRAPPER_ID : undefined}>
        <DatePicker
          name={name}
          id={name}
          minDate={minDate}
          maxDate={maxDate}
          locale={locale}
          selected={value}
          dateFormat={dateFormat}
          onChange={onChange}
          autoComplete="off"
          renderCustomHeader={(props) => (
            <CalendarHeader
              {...props}
              minDate={minDate}
              monthsShown={monthsShown}
            />
          )}
          portalId={DATEPICKER_WRAPPER_ID}
          monthsShown={monthsShown}
          inline={inline}
          {...datePickerProps}
        />
        {!inline && (
          <DateInputIconWrapper>
            <BalloonIcon large={true} icon={'today'} />
          </DateInputIconWrapper>
        )}
      </DateInputWrapper>
    </InputWrapper>
  );
};

DateInput.defaultProps = {
  monthsShown: 1,
  isClearable: true,
};

DateInput.propTypes = {
  errorText: PropTypes.string,
  format: PropTypes.string,
  hint: PropTypes.string,
  hintId: PropTypes.string,
  initialValue: PropTypes.string, //received from rails d.M.yyyy
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

/**
 * Provides a form-integrated wrapper for the DateInput component.
 * Handles form state management and date format conversion between ISO and Date object.
 *
 * Props:
 *   dateFormat: custom date format string
 *   name: unique identifier for the form field
 *   label: label text for the input
 *   labelProps: additional props for the label component
 *   required: whether the field is required
 *   minDate: earliest selectable date
 *   maxDate: latest selectable date
 *   fieldProps: additional props passed to the Field component
 */
export const FormDateInput = ({
  dateFormat,
  hint,
  hintId,
  label,
  labelProps,
  maxDate,
  minDate,
  name,
  required,
  ...fieldProps
}) => {
  return (
    <Field
      parse={objToISODate}
      format={stringToDate}
      name={name}
      required={required}
      {...fieldProps}
    >
      {({ input, meta }) => (
        <DateInput
          {...input}
          format={dateFormat}
          errorText={getError(meta)}
          hint={hint}
          hintId={hintId}
          labelProps={{ label, required, ...labelProps }}
          maxDate={maxDate}
          minDate={minDate}
          name={name}
          required={required}
          value={input.value || null}
        />
      )}
    </Field>
  );
};

FormDateInput.propTypes = {
  ...Field.propTypes,
  dateFormat: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  required: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export const ERROR_MESSAGE_CODES = {
  REQUIRED: 'field.error_message.required',
  EMAIL: 'field.error_message.email',
  HIGHER: 'field.error_message.min',
  LOWER: 'field.error_message.max',
  NOT_LOWER: 'field.error_message.not_lower',
  NOT_HIGHER: 'field.error_message.not_higher',
};

export const isEmailValid = (email) =>
  Boolean(
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  );
export const emailValidation =
  ({ translate, customMessage }) =>
  (value) =>
    !value || isEmailValid(value)
      ? undefined
      : customMessage || translate(ERROR_MESSAGE_CODES.EMAIL);

export const isHigherThan = (value, condition) => value > condition;

export const higherThanValidation =
  ({ condition, translate, customMessage }) =>
  (value) =>
    validateHigherThan({ condition, translate, customMessage, value });

export const validateHigherThan = ({
  condition,
  translate,
  customMessage,
  value,
}) =>
  (value !== 0 && !value) || isHigherThan(Number(value), condition)
    ? undefined
    : customMessage || translate(ERROR_MESSAGE_CODES.HIGHER, { condition });

export const notHigherThanValidation =
  ({ condition, translate, customMessage }) =>
  (value) =>
    (value !== 0 && !value) || !isHigherThan(Number(value), condition)
      ? undefined
      : customMessage ||
        translate(ERROR_MESSAGE_CODES.NOT_HIGHER, { condition });

export const isLowerThan = (value, condition) => value < condition;

export const lowerThanValidation =
  ({ condition, translate, customMessage }) =>
  (value) =>
    (value !== 0 && !value) || isLowerThan(Number(value), condition)
      ? undefined
      : customMessage || translate(ERROR_MESSAGE_CODES.LOWER, { condition });

export const notLowerThanValidation =
  ({ condition, translate, customMessage }) =>
  (value) =>
    (value !== 0 && !value) || !isLowerThan(Number(value), condition)
      ? undefined
      : customMessage ||
        translate(ERROR_MESSAGE_CODES.NOT_LOWER, { condition });

// if we have the multi (used with select) flag set to true
// we are expecting an array of values
const isRequiredValid = (value, multi) =>
  multi
    ? Boolean(value?.length)
    : value === false || value === 0 || Boolean(value);

export const requiredValidation = ({
  value,
  translate,
  customMessage,
  multi,
}) =>
  isRequiredValid(value, multi)
    ? undefined
    : customMessage || translate(ERROR_MESSAGE_CODES.REQUIRED);

const validationsWithRequired =
  ({ validate, translate, customMessage, multi }) =>
  (fieldValue, formValues, field) => {
    const requiredError = requiredValidation({
      value: fieldValue,
      translate,
      customMessage,
      multi,
    });
    if (requiredError) return requiredError;
    return validate ? validate(fieldValue, formValues, field) : undefined;
  };

// returns validation function that can be use with react-final-form Fileds
export const generateValidations =
  ({ required, validate, translate, disabled, customMessage, multi }) =>
  (fieldValue, formValues, field) => {
    if (disabled) return undefined;
    const validations = required
      ? validationsWithRequired({ translate, validate, customMessage, multi })
      : validate;

    return validations ? validations(fieldValue, formValues, field) : undefined;
  };

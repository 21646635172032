import { styled } from 'styled-components';

import { iconsFont } from './variables';

export const StyledSelectAllButtonWrapper = styled.div`
  padding: 0;
  & > div {
    text-transform: none;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 0;
    &:active {
      background-image: none;
    }
    button {
      padding: 8px 18px;
      border: none;
      background: inherit;
      span.material-symbols-outlined {
        margin-left: 0;
      }
    }
    button:first-child {
      padding-right: 0;
    }
    button:last-child {
      padding-left: 0;
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledSelectAllButton = styled.span`
  padding: 8px 18px;
  border: none;
  background: inherit;
`;

// we use span instead of button because the
// wrapper element is already a button, so it will
// raise an error
export const Button = styled.span`
  text-transform: none;
  padding-left: 4px;
  padding-right: 12px;

  .material-symbols-outlined {
    color: #666;
    font-size: 18px;
  }

  span {
    vertical-align: middle;
    margin-left: 12px;
  }
  span + span,
  span.material-symbols-outlined {
    margin-left: 6px;
  }

  &:hover {
    cursor: pointer;
  }

  &::after {
    font-family: ${iconsFont};
    padding-left: 3px;
    vertical-align: middle;
  }
  &.closed::after {
    content: 'arrow_drop_down';
  }
  &.open::after {
    content: 'arrow_drop_up';
  }
`;

import PropTypes from 'prop-types';

import useVisibility from '../../../../lib/use_visibility';
import {
  DropdownButton,
  generateDropdownButtonClassNames,
} from '../../dropdown';
import { MonthYearList } from './month_year_list';
import { HeaderTextWrapper } from './styles';
import { useGetMonthYearText } from './utils';

/**
 * Provides a dropdown component for selecting month and year combinations.
 * Displays the current month/year and expands to show a list of available options
 * when clicked.
 *
 * Props:
 *   date: currently selected date used to display current month/year
 *   minDate: optional minimum date that restricts the earliest selectable month/year
 *   changeMonth: callback function that handles month selection change,
 *                provided by the react-datepicker component
 *   changeYear: callback function that handles the year selection change,
 *               provided by the react-datepicker component
 */
export const MonthYearDropdown = ({
  date,
  minDate,
  changeMonth,
  changeYear,
}) => {
  const getMonthYearText = useGetMonthYearText();
  const { hide, shown, toggleVisibility } = useVisibility();
  const onItemClick = ({ month, year }) => {
    changeYear(year);
    changeMonth(month);
    hide();
  };
  return (
    <HeaderTextWrapper>
      <DropdownButton
        className={generateDropdownButtonClassNames({ shown })}
        onClick={(e) => {
          e.preventDefault();
          toggleVisibility();
        }}
      >
        <h4>{getMonthYearText(date)}</h4>
      </DropdownButton>
      {shown && (
        <MonthYearList
          displayDate={date}
          minDate={minDate}
          onItemClick={onItemClick}
        />
      )}
    </HeaderTextWrapper>
  );
};

MonthYearDropdown.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date),
  changeMonth: PropTypes.func.isRequired,
  changeYear: PropTypes.func.isRequired,
};

import PropTypes from 'prop-types';

import Icon from '../../icon';
import { MonthYearDropdown } from './month_year_dropdown';
import { CalendarHeaderWrapper, ChangeMonthButton } from './styles';

/**
 * Provides the CalendarHeader component for the DateInput's calendar navigation.
 * Handles month/year navigation and selection through a combination of arrow buttons
 * and a dropdown menu.
 *
 * Props:
 *   changeMonth: callback function to handle month changes from the dropdown,
 *                provided by the react-datepicker component
 *   changeYear: callback function to handle year changes from the dropdown,
 *               provided by the react-datepicker component
 *   customHeaderCount: index of current header, from 0 to `monthsShown`,
 *                      provided by the react-datepicker component
 *   decreaseMonth: callback function to handle previous month button click,
 *                  provided by the react-datepicker component
 *   increaseMonth: callback function to handle next month button click,
 *                  provided by the react-datepicker component
 *   minDate: optional minimum date that restricts how far back users can navigate
 *   monthsShown: total shown months count
 *   nextMonthButtonDisabled: determines if the next month button should be disabled,
 *                            provided by the react-datepicker component
 *   prevMonthButtonDisabled: determines if the previous month button should be disabled,
 *                            provided by the react-datepicker component
 */
export const CalendarHeader = ({
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  minDate,
  monthDate,
  customHeaderCount,
  monthsShown,
}) => {
  const isFirst = customHeaderCount === 0;
  const isLast = customHeaderCount === monthsShown - 1;
  const changeMonthWithOffset = (month) => {
    changeMonth(month - customHeaderCount);
  };
  return (
    <CalendarHeaderWrapper>
      <ChangeMonthButton
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled || !isFirst}
      >
        <Icon name="arrow_back_ios" size={24} />
      </ChangeMonthButton>
      <MonthYearDropdown
        date={monthDate}
        minDate={minDate}
        changeYear={changeYear}
        changeMonth={changeMonthWithOffset}
      />
      <ChangeMonthButton
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled || !isLast}
      >
        <Icon name="arrow_forward_ios" size={24} />
      </ChangeMonthButton>
    </CalendarHeaderWrapper>
  );
};

CalendarHeader.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  changeYear: PropTypes.func.isRequired,
  changeMonth: PropTypes.func.isRequired,
  decreaseMonth: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
  prevMonthButtonDisabled: PropTypes.bool.isRequired,
  nextMonthButtonDisabled: PropTypes.bool.isRequired,
  minDate: PropTypes.instanceOf(Date),
};

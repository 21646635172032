import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field as FormField } from 'react-final-form';
import styled from 'styled-components';
import useI18n from '../../../lib/use_i18n';
import { disabledStyle } from '../styles/form';
import { generateValidations } from './validations';

const StyledFormField = styled(FormField)`
  ${({ disabled }) => (disabled ? disabledStyle : '')}
  opacity: 1;
`;

// Without this, the final form would remove empty fields from
// form values. This is problematic because it would not
// overwrite an initial truthy value with an empty string,
// when making rest calls, from onSubmit callback.
const identity = (value) => value;

// Value prop on input field should not be `null` but `undefined`
// or an empty string. However from the backend side we receive
// null for the non-existing values. In the Input components we override
// the null with the appropriate value, but that would make a field 'dirty'
// if not for this custom equality check.
const isEqual = (valueA, valueB) => {
  if (valueA || valueB) {
    return valueA === valueB;
  }
  return true;
};

/**
 * A wrapper around the react-final-form Field component,
 * extended to handle `required` validation.
 * we could also pass the `customMessage` to be shown if the required
 * validation fails, instead of showing the generic one
 **/
export const Field = ({
  children,
  customMessage,
  disabled,
  required,
  validate,
  ...fieldProps
}) => {
  const { translate } = useI18n();
  const validations = useMemo(
    () =>
      generateValidations({
        required,
        validate,
        translate,
        disabled,
        customMessage,
        multi: fieldProps.multi,
      }),
    [required, validate, translate, disabled, customMessage]
  );

  // In order to re-trigger the validation if disabled/required values
  // are changed we have modify the data prop on the Field component
  return (
    <StyledFormField
      isEqual={isEqual}
      data={`${fieldProps.name}${disabled ? '#disabled' : ''}${
        required ? '#required' : ''
      }`}
      parse={identity}
      {...fieldProps}
      required={required}
      disabled={disabled}
      validate={validations}
    >
      {children}
    </StyledFormField>
  );
};

Field.propTypes = {
  ...Field.propTypes,
  customMessage: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  validate: PropTypes.func,
};

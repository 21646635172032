import PropTypes from 'prop-types';
import { isSameMonth } from 'date-fns';

import { MonthYearItem, MonthYearListWrapper } from './styles';
import { generateYearMonthList, useGetMonthYearText } from './utils';

/**
 * Provides a scrollable list of month/year combinations for selection.
 * Displays up to 12 months starting from either the minimum date or three months ago.
 * Highlights current month and today's month for better user orientation.
 *
 * Props:
 *   minDate: optional minimum date that restricts the earliest shown month
 *   onItemClick: callback function triggered when a month/year combination is selected
 *   displayDate: currently selected date used to highlight the active month
 */
export const MonthYearList = ({ minDate, onItemClick, displayDate }) => {
  const monthOptions = generateYearMonthList(minDate);
  const getMonthYearText = useGetMonthYearText();
  return (
    <MonthYearListWrapper>
      {monthOptions.map(({ month, year, date }) => (
        <MonthYearItem
          today={isSameMonth(date, new Date())}
          active={isSameMonth(date, displayDate)}
          key={`${year}${month}`}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              onItemClick({ month, year });
            }}
          >
            <span>{getMonthYearText(date)}</span>
          </button>
        </MonthYearItem>
      ))}
    </MonthYearListWrapper>
  );
};

MonthYearList.propTypes = {
  minDate: PropTypes.instanceOf(Date),
  onItemClick: PropTypes.func.isRequired,
  displayDate: PropTypes.instanceOf(Date).isRequired,
};

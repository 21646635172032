import { createGlobalStyle, styled } from 'styled-components';
import { withCustomProps, withOpacity } from '../../styles/utils';
import {
  backgroundBlue,
  backgroundGray,
  borderLightGray,
  darkGray,
  deepBlue,
  fontFamily,
  fontSizeS,
  gray,
  lightBlue,
  lightGray,
  placeholderGray,
  popoverZIndex,
} from '../../styles/variables';

// classes responsible for setting the background color of the selected items (days, months, years, quarters).
const selectedClasses = `.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range`;

// classes responsible for setting the background color of hovered items
const hoverClasses = `.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover`;

// classes describing calendar item, e.g. day of the week in the header or number in the calendar body
const calendarItemClasses = `.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name`;

// classesresponsible for the style of `today` item
const todayClass = `.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today`;

// classes responsible for setting the background color of current keybord navigation position
const keyboardSelectedClasses = `.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected`;

// classes describing the look of a disabled item
const disabledClasses = `.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled`;

// classes active for the items that are between seleced minimum and **hovered** maximum, limits included
const inSelecetingRangeClasses = `.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range,  .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range`;

// first and last item that also falls under `inSelecetingRangeClasses`
const selectingRangeLimits = `.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end`;

const StyledDateInputWrapper = styled.label`
  width: 100%;
  display: flex;
  color: ${lightGray};
  input {
    height: 39px;
    width: 100%;
    &:focus-visible {
      outline: none;
    }
  }

  .react-datepicker__close-icon::after {
    color: ${lightGray};
    background: transparent;
    font-size: 18px;
  }
`;

export const DATEPICKER_WRAPPER_ID = 'datepicker-portal';

export const DateInputWrapper = ({ children, id }) => {
  return (
    <StyledDateInputWrapper id={id}>
      <DatePickerStyle />
      {children}
    </StyledDateInputWrapper>
  );
};

// we want to change the default react-datepicker style to be more in line with our ui.
// To do that, we are overriding the existing style (found in react-datepicker.css).
export const DatePickerStyle = createGlobalStyle`
  #${DATEPICKER_WRAPPER_ID} {
    background-color: transparent;
    .react-datepicker-wrapper {
      width: 100%;
    }
    .react-datepicker-popper {
      z-index: ${popoverZIndex + 100}
    }

    .react-datepicker {
      font-family: ${fontFamily};
      color: ${lightGray};
      display: flex;
    }

    .react-datepicker__month-container {
      padding: 16px;
      &:not(:last-child){
      border-right: 1px solid ${borderLightGray}
    }

    }
    
      .react-datepicker__header {
      border-bottom: none;
      background: transparent;
      cursor: initial;
      .react-datepicker__day-names {
        margin-bottom: 0;
        border-radius: 3px;
        .react-datepicker__day-name {
          font-weight: bold;
          color: ${lightGray};
        }
      }
    }

    ${calendarItemClasses} {
      width: 36px;
      line-height: 36px;
      font-size: ${fontSizeS};
      color: ${darkGray};
    }

    ${hoverClasses}, ${selectedClasses}, ${todayClass}, ${keyboardSelectedClasses} {
      border-radius: 50%;
    }

    ${hoverClasses} {
      &:not(${selectedClasses}, ${keyboardSelectedClasses}) {
        background-color:  ${withOpacity(backgroundBlue, 0.4)};
      }
    }

    ${selectedClasses} {
      background: ${deepBlue};
      color: white;
      &:focus-visible {
        outline: none;
      }
    }

    ${todayClass} {
      font-weight: normal;
      border: 1px solid ${lightGray};
    }

    ${keyboardSelectedClasses} {
      &:not(${selectedClasses}) {
        background: ${backgroundBlue};
      }
      &:focus-visible {
        outline: none;
      }
    }

    ${disabledClasses} {
      background: ${backgroundGray};
      border-radius: 50%;
      pointer-events: none;
      color: ${gray}
    }

    ${inSelecetingRangeClasses} {
      background-color:  ${withOpacity(deepBlue, 0.4)};
      color: white;
    }

    ${selectingRangeLimits}{
      background-color: ${deepBlue}
    }

    .react-datepicker__day--outside-month {
      color: ${gray};
    }
    .react-datepicker__day-names,
    .react-datepicker__week {
      cursor: initial;
    }
  }
`;

export const DateInputIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > * {
    height: 24px;
  }
  .material-symbols-outlined {
    cursor: pointer;
    color: ${placeholderGray};
  }
  .material-symbols-outlined:hover {
    color: ${lightBlue};
  }
`;

export const MonthYearItem = withCustomProps(styled.li`
  list-style: none;
  button {
    width: 100%;
    background: transparent;
    border: none;
    padding: 3px 16px;
    justify-content: flex-start;
    text-align: left;
    color: ${darkGray};
    display: flex;
    align-items: center;
    &:hover {
      background: ${backgroundBlue};
    }
    ${({ today }) => today && 'font-weight: bold;'}
    ${({ active }) => active && activeMonthStyle}
  }
`);

const activeMonthStyle = `
  background: ${deepBlue}; 
  color: white;
  &:hover {
    background: ${deepBlue}; 
  }
`;

export const HeaderTextWrapper = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  h4 {
    height: 40px;
    margin: 0;
    height: 100%;
    text-align: center;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  & > [role='button'] {
    height: 40px;
    width: 100%;
    background: transparent !important;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    &:after {
      font-size: 16px;
    }
  }
  ul {
    margin-top: -10px;
  }
`;

export const MonthYearListWrapper = styled.ul`
  display: inline-flex;
  flex-direction: column;
  padding: 8px 0;
  background: white;
  border: 1px solid ${borderLightGray};
  border-radius: 3px;
  box-shadow: 0 0 1px 0 ${lightGray};
  float: left;
  transform: translateX(-50%);
  left: 50%;
  position: relative;
  z-index: ${popoverZIndex + 10};
`;

export const CalendarHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: initial;
`;

export const ChangeMonthButton = styled.span.attrs({ role: 'button' })`
  width: 36px;
  background: transparent;
  display: flex;
  align-items: center;
  border: none;
  ${({ disabled }) => disabled && disabledStyle}
  display: flex;
  justify-content: flex-end;
`;

const disabledStyle = `visibility: hidden; pointer-events: none;`;

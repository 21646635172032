import PropTypes from 'prop-types';

import useI18n from '../../../lib/use_i18n';
import { Flex } from '../styles/flex';

export const DropdownFooter = ({ handleReset, handleFilter, ...style }) => {
  const { translate } = useI18n();
  return (
    <Flex justifyContent="flex-end" gap="4px" {...style}>
      <button className="btn btn-default" type="button" onClick={handleReset}>
        {translate('app.actions.reset')}
      </button>
      <button className="btn btn-primary" onClick={handleFilter}>
        {translate('app.actions.filter')}
      </button>
    </Flex>
  );
};

DropdownFooter.propTypes = {
  handleReset: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

import isPropValid from '@emotion/is-prop-valid';
import { forwardRef } from 'react';
import { StyleSheetManager } from 'styled-components';
import { kebabise } from '../../../util';

const OWN_PROPS = ['children', 'theme', 'className'];

/**
 *
 * since upgrade to styled-components v6 propsToAttributes
 * must be used inside the withCustomProps wrapper. e.g.
 *
 *    export const Flex = withCustomProps(styled.div`
 *      display: flex;
 *      ${propsToAttributes}
 *    `);
 *
 */
export const propsToAttributes = (props = {}) =>
  Object.keys(props)
    .filter((prop) => !OWN_PROPS.includes(prop) && !prop.startsWith('data-'))
    .map((key) => `${kebabise(key)}: ${props[key]};`)
    .join('\n');

/**
 * after upgrading to version 6, in order to not rename every prop we
 * are passing to a styled component by adding the prefix '$', we are
 * creating a wrapper that will mimic the bahavior of the older version (5)
 * https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
 */
export const withCustomProps = (WrappedComponent) => {
  const wrapper = forwardRef((props, ref) => (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <WrappedComponent ref={ref} {...props} />
    </StyleSheetManager>
  ));
  wrapper.displayName = 'withCustomProps';
  return wrapper;
};

// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

// transforms hex values to rgba
export const withOpacity = (hex, opacity = 1) => {
  if (hex.length === 4) {
    hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

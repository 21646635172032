import { format } from 'date-fns';
import de from 'date-fns/locale/de';

import useI18n from '../../../../lib/use_i18n';

export const objToISODate = (dateObj) =>
  !dateObj
    ? null
    : [
        dateObj?.getFullYear(),
        (dateObj?.getMonth() + 1).toString().padStart(2, '0'),
        dateObj?.getDate().toString().padStart(2, '0'),
      ].join('-');

// we are getting the date value as d.M.yyyy (if re rendering page after the unrelated submit error)
// or yyyy-MM-dd string from ruby form
// we need to transform it to js Date obj.
// it would become redundant once we migrate whole publication form to the react side
export const stringToDate = (dateString) => {
  if (!dateString) return null;
  if (dateString instanceof Date) return dateString;

  const isDotFormating = dateString.includes('.');

  if (!isDotFormating) return new Date(dateString);

  var parts = dateString.split('.'); // Split the date string by '.' or '-' characters
  isDotFormating && parts.reverse();

  // Create a new Date object with the parsed day, month, and year
  return new Date(parts.join('-'));
};

// Takes an ISO date string or null and returns either that date or 3 months ago,
// whichever is more recent. Used to determine the earliest selectable date.
const calculateStartDate = (dateValue) => {
  const currentDate = new Date();
  const inputDate = dateValue ? new Date(dateValue) : null;

  // Calculate three months ago
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

  // Determine the starting date
  return inputDate && inputDate >= threeMonthsAgo ? inputDate : threeMonthsAgo;
};

// Takes a start date and generates an array of the next 12 months.
// Each month object contains: { year: number, month: number (0-11), date: Date }
const generateMonthOptions = (startDate) => {
  return Array.from({ length: 12 }, (_, i) => {
    const year = startDate.getFullYear();
    const month = startDate.getMonth() + i;
    const date = new Date(year, month, 1); // Creates a new Date instance for the first day of the month
    return {
      year: date.getFullYear(),
      month: date.getMonth(),
      date,
    };
  });
};

// Creates a list of 12 months starting from either the provided date
// or 3 months ago (whichever is more recent)
export const generateYearMonthList = (dateValue) => {
  const startDate = calculateStartDate(dateValue);
  return generateMonthOptions(startDate);
};

const LOCALES = {
  de,
};

// Returns a function that formats dates as "Month YYYY" in the current locale
// Example: "Januar 2024" for German locale
export const useGetMonthYearText = () => {
  const { locale } = useI18n();
  const getMonthYearText = (date) =>
    format(date, 'MMMM yyyy', { locale: LOCALES[locale] });

  return getMonthYearText;
};

import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { usePopover } from '../popover/use_popover';
import { DropdownButton } from './dropdown_button';
import {
  generateDropdownButtonClassNames,
  generateDropdownId,
} from './lib/utils';

export const Dropdown = ({
  active,
  buttonLabel,
  children,
  htmlIdPrefix,
  icon,
  name,
  selectedCount,
  style,
}) => {
  const { Popover, toggle: toggleVisibility, shown } = usePopover();

  // we don't want to provide toggleVisibility with an
  // event param, it would prevent closing of the other open
  // dropdown menus
  const onDropdownButtonClick = useCallback(
    () => toggleVisibility(),
    [toggleVisibility]
  );

  return (
    <Popover
      trigger={
        <DropdownButton
          label={buttonLabel}
          shown={shown}
          icon={icon}
          id={generateDropdownId({ htmlIdPrefix, name })}
          onClick={onDropdownButtonClick}
          active={active}
          className={generateDropdownButtonClassNames({
            active,
            shown,
          })}
          selectedCount={selectedCount}
        />
      }
      hideArrow={true}
      placement="bottom-start"
      style={{ ...DROPDOWN_POPOVER_STYLE, ...style }}
    >
      {children}
    </Popover>
  );
};

Dropdown.propTypes = {
  active: PropTypes.bool,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.element,
  htmlIdPrefix: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  selectedCount: PropTypes.number,
  style: PropTypes.object,
};

export const DROPDOWN_POPOVER_STYLE = {
  width: 'inherit',
  maxHeight: '420px',
  minWidth: '160px',
  overflowY: 'auto',
  padding: 0,
};

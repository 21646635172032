import styled from 'styled-components';
import { popoverZIndex } from './variables';

export const PopoverWrapper = styled.span`
  position: relative;
  display: ${({ display }) => display || 'inline-block'};
`;

export const TriggerWrapper = styled.span`
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  text-align: initial;
  position: relative;
  ${({ display }) =>
    display === 'block' ? 'display: block; width: 100%;' : ''}
`;

export const StyledPopoverBase = styled.div`
  max-width: fit-content;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  background: white;
  padding: 8px;
  z-index: ${popoverZIndex};
  width: ${({ width }) => width || '300px'};
  font-weight: normal;
  border-radius: 2px;
  line-height: 24px;
  &[data-popper-placement^='top'] > #popover_arrow {
    bottom: -4px;
    &::before {
      transform: rotate(-45deg);
    }
  }

  &[data-popper-placement^='bottom'] > #popover_arrow {
    top: -4px;
    &::before {
      transform: rotate(135deg);
    }
  }

  &[data-popper-placement^='left'] > #popover_arrow {
    right: -4px;
    &::before {
      transform: rotate(135deg);
    }
  }

  &[data-popper-placement^='right'] > #popover_arrow {
    left: -4px;
    &::before {
      transform: rotate(135deg);
    }
  }
  #popover_arrow,
  #popover_arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  #popover_arrow {
    visibility: hidden;
  }

  #popover_arrow::before {
    box-shadow: -2px 2px 10px -1px rgba(0, 0, 0, 0.3);
    visibility: visible;
    content: '';
  }
`;

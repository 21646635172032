import styled from 'styled-components';

export const FilterWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 12px 12px;
  align-items: center;

  margin: 0 -32px 0 -16px;
  padding: 32px 32px 0 16px;
  background-color: #fff;

  z-index: 3;

  div {
    position: relative;
  }
`;

export const Separator = styled.div`
  border-left: 1px #ddd solid;
  height: 42px;
`;
